import { FUNDS_ITEM } from 'constants/funds'
import { calcFundPrice } from 'pages/FundDetail'
import { fundData } from '../state/funds/reducer'
import { useUo2FundContract, useTokenContract, useWETHContract } from '../hooks/useContract'
import { AllowItems } from 'components/FundDetail/DetailBuyAndRedeem'
import { Erc20, Uo1FundAbi, Uo1StrategyAbi, Uo2FundAbi, Uo2StrategyAbi, Weth } from '../abis/types'
import BigNumber from 'bignumber.js'
import { BigNumber as BN } from '@ethersproject/bignumber'
import { currencyFormat } from './currencyAndAmountFormat'
interface checkBuyAmoutValue {
  val: string
  fundsdatas: FUNDS_ITEM | undefined
  isETH: boolean
  fundContract: Uo2FundAbi | null
  allowItems: AllowItems[]
  ioTokens: string
  setBuyAssets: React.Dispatch<React.SetStateAction<string>>
  usdt2Usd: string | undefined
  setBuyAmount: React.Dispatch<React.SetStateAction<string>>
  tokenAddress: string
}

interface checkReedAmoutValue {
  val: string
  fundsdatas: FUNDS_ITEM | undefined
  isETH: boolean
  fundContract: Uo2FundAbi | null
  allowItems: AllowItems[]
  ioTokens: string
  setPerformanceFee: React.Dispatch<React.SetStateAction<BN>>
  setSellAmount: React.Dispatch<React.SetStateAction<string>>
  tokenAddress: string
  account: string | null | undefined
  fundData: fundData
}

const estimateTokenValue = async (
  fundContract: Uo2FundAbi | null,
  coinItem: AllowItems | undefined,
  val: string,
  ioTokens: string
) => {
  if (!coinItem) {
    return
  }
  const estimateValue = await fundContract?.estimateTokenValue(
    coinItem.address,
    ioTokens,
    BN.from(new BigNumber(val).times(10 ** coinItem.decimals).toFixed())
  )

  return estimateValue
}
// const convertToShare = (estimateValue: BN | undefined, fundContract: Fund | null) => {}

/**
 * 此方法用于判断选择的是否为eth
 * @param wethItem weth的decimal和address
 * @param coinItem 选择币种的decimal和address
 * @param isETH 是否为eth
 */
export const chooseEthOrCoin = (wethItem: AllowItems | undefined, coinItem: AllowItems | undefined, isETH: boolean) => {
  const chooseMap = new Map([
    ['true', [wethItem]],
    ['false', [coinItem]],
    ['default', [coinItem]],
  ])
  const action = chooseMap.get(`${isETH}`) || chooseMap.get('default')
  return action && action[0]
}

export const checkBuyAmount = async ({
  val,
  fundsdatas,
  isETH,
  fundContract,
  allowItems,
  ioTokens,
  setBuyAssets,
  usdt2Usd,
  setBuyAmount,
  tokenAddress,
}: checkBuyAmoutValue) => {
  const wethItem = allowItems.find((item) => item.symbol === 'WETH')
  const coinItem = allowItems.find((item) => item.address === tokenAddress)
  if (!fundsdatas || !Boolean(val) || Number(val) < 0 || !usdt2Usd) {
    return
  }
  try {
    const tokenItem = chooseEthOrCoin(wethItem, coinItem, isETH)
    const estimateValue = await estimateTokenValue(fundContract, tokenItem, val, ioTokens)
    const convertToShareValue = await fundContract?.convertToShare(estimateValue ? estimateValue : 0)
    const globalNetValue = await fundContract?.globalNetValue()
    setBuyAssets(
      currencyFormat(
        new BigNumber((convertToShareValue ? convertToShareValue : '0').toString())
          .dividedBy(10 ** fundsdatas.decimals)
          .times(new BigNumber((globalNetValue ? globalNetValue : '0').toString()).dividedBy(10 ** 18).toFixed())
          .times(usdt2Usd)
          .toFixed()
      ) as string
    )

    setBuyAmount(
      new BigNumber((convertToShareValue ? convertToShareValue : '0').toString())
        .dividedBy(10 ** fundsdatas.decimals)
        .toFixed(fundsdatas.decimals)
    )
  } catch (e) {
    console.error(e)
    setBuyAmount(new BigNumber(0).toFixed(fundsdatas.decimals))
  }

  return
}
export const checkReedAmount = async ({
  val,
  fundsdatas,
  isETH,
  fundContract,
  allowItems,
  ioTokens,
  setSellAmount,
  setPerformanceFee,
  tokenAddress,
  account,
  fundData,
}: checkReedAmoutValue) => {
  if (!fundContract || !Boolean(val) || Number(val) <= 0 || !fundsdatas || !account) {
    return
  }

  try {
    const wethItem = allowItems.find((item) => item.symbol === 'WETH')
    const coinItem = allowItems.find((item) => item.address === tokenAddress)
    const convertToCash = await fundContract.convertToCash(
      new BigNumber(val).times(10 ** fundsdatas.decimals).toNumber()
    )
    let currTokenAmount = BN.from(0)
    const netWorth = await fundContract.globalNetValue()
    const performanceFee = await fundContract.calcPerformanceFee(account, netWorth)
    const streamFee: BN = await fundContract.calcRatioFee(4, BN.from(fundData.fundBalance))
    const finalFee = new BigNumber(performanceFee.toString()).plus(streamFee.toString()).toString()
    const finalAmount =
      Number(fundData.fundBalance) >= Number(finalFee)
        ? convertToCash
        : BN.from(new BigNumber(convertToCash.toString()).minus(finalFee))
    setPerformanceFee(performanceFee)

    const tokenItem = chooseEthOrCoin(wethItem, coinItem, isETH)
    if (!tokenItem) {
      return
    }
    currTokenAmount = await fundContract.estimateTokenValue(ioTokens, tokenItem.address, finalAmount)
    setSellAmount(
      new BigNumber(currTokenAmount.toString()).dividedBy(10 ** tokenItem.decimals).toFixed(fundsdatas.decimals)
    )
  } catch (e) {
    console.error(e)
    setSellAmount(new BigNumber(0).toFixed(fundsdatas.decimals))
  }
}
