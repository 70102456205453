/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Link } from 'react-router-dom'
import { TOKENS } from '../../constants/tokens'
import BigNumber from 'bignumber.js'
import { fundData } from '../../state/funds/reducer'
import { amountFormat } from '../../utils/currencyAndAmountFormat'
import { currencyFormat } from '../../utils/currencyAndAmountFormat'
import { calcFundPrice } from '../../pages/FundDetail'
import { useStrategyData } from '../../hooks/useStrategyData'
interface FundUnderlyItem {
  name: string
  id: string
  decimals: number
  icon: string
  trend: string
  address: string
  priceUsd: number
  change24h: number
}
interface IProps {
  fund: fundData
  walletLocked: boolean
}
const DashbordItems = styled.div`
  width: 340px;
  margin-top: 20px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px dashed #dddddd;
  box-sizing: border-box;
  padding: 20px;
  &:nth-child(3n-1) {
    margin: 20px 14px 0;
  }
  @media screen and (max-width: 1160px) {
    width: 100%;
    padding: 10px;
    &:nth-child(3n-1) {
      margin: 20px 0 0;
    }
  }
`
const DashbordHead = styled.div`
  display: flex;
  justify-content: space-between;
`
const HeadLeft = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 32px;
`
const FundIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 4px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
`
const FundText = styled.div`
  width: 100px;
  display: grid;
`
const FundName = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #222222;
  font-weight: 500;
  display: inline-block;
  width: 100%;
`
const QuoteChange = styled.span`
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  display: inline-block;
  margin-top: 2px;
`
const QuoteChangeRed = styled(QuoteChange)`
  color: #00b464;
`
const QuoteChangeGre = styled(QuoteChange)`
  color: #fa4d56;
`
const HeadRight = styled.div`
  background-color: #f6f7f9;
  box-sizing: border-box;
  height: 32px;
  padding: 5px;
  position: relative;
  display: block;
  border-radius: 16px;
  text-align: left;
`
const CoinItem = styled.img`
  width: 22px;
  height: 22px;
  background-color: #ffffff;
  border-radius: 50%;
  margin-right: -5px;
  margin-left: -5px;
  display: inline;

  &:first-child {
    margin-left: 5px;
  }

  &:last-child {
    margin-right: 5px;
  }
`
const Balance = styled.p`
  font-size: 32px;
  line-height: 32px;
  color: #222222;
  font-weight: 600;
  margin: 0;
`
const Tag = styled.span`
  padding: 5px 8px;
  font-size: 14px;
  line-height: 14px;
  display: inline-block;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #222222;
  vertical-align: 6px;
  margin-left: 10px;
`
const BalanceConvert = styled.p`
  margin-top: 6px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
`
const ItemBtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  a {
    text-decoration: none;
  }
`
const Btn = styled.div`
  padding: 13px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  margin-left: 8px;
  font-weight: 600;
  color: #222222;
  cursor: pointer;
`
const BuyBtn = styled(Btn)`
  color: #2b878c;
  border-color: #2b878c;
  width: 106px;
  margin-left: 0;
  @media screen and (max-width: 1160px) {
    width: auto;
  }
`
const LoadingBox = styled(DashbordItems)`
  text-align: center;
`

const LoadingText = styled.p`
  margin: 0;
  height: 51px;
  font-size: 16px;
  line-height: 50px;
  span {
    cursor: pointer;
    text-decoration: underline;
  }
`
export function DashbordItem({ fund, walletLocked }: IProps) {
  const fundsPrice = calcFundPrice(fund)
  const underly = useStrategyData(fund.address)

  return (
    <DashbordItems>
      <DashbordHead>
        <HeadLeft>
          <FundIcon src={fund.icon} alt="" />
          <FundText>
            <Link to={{ pathname: '/fund/' + fund.address, state: { status: 'buy' } }}>
              <FundName>{fund.symbol ? fund.symbol : ''}</FundName>
            </Link>
            {/*
            TODO
            {fund.changeDayStatus === 'down' ? (
              <QuoteChangeRed>
                {fund.changeDayStatus === 'down' ? '' : '+'}
                {fund.changeDay ? fund.changeDay : ''}%
              </QuoteChangeRed>
            ) : (
              <QuoteChangeGre>
                {fund.changeDayStatus === 'down' ? '' : '+'}
                {fund.changeDay ? fund.changeDay : ''}%
              </QuoteChangeGre>
            )} */}
          </FundText>
        </HeadLeft>
        {underly.length > 0 ? (
          <HeadRight>
            {underly.map((item, index: number) => (
              <CoinItem key={item.icon + index.toString()} src={item.icon} />
            ))}
          </HeadRight>
        ) : null}
      </DashbordHead>
      <div style={{ marginTop: '20px' }}>
        <Balance>
          <span style={{ cursor: 'pointer' }}>
            {!walletLocked
              ? 'lock'
              : amountFormat(new BigNumber(fund.fundBalance).dividedBy(10 ** fund.decimals).toFixed())}
          </span>
          <Tag>{fund.symbol ? fund.symbol : ''}</Tag>
        </Balance>
        {!walletLocked ? (
          <BalanceConvert>&nbsp;</BalanceConvert>
        ) : (
          <BalanceConvert>
            {currencyFormat(
              new BigNumber(fund.fundBalance)
                .multipliedBy(fundsPrice)
                .dividedBy(10 ** fund.decimals)
                .toFixed(2)
            )}
          </BalanceConvert>
        )}
      </div>
      <ItemBtnBox>
        <Link to={{ pathname: '/fund/' + fund.address, state: { status: 'buy' } }}>
          <BuyBtn>购买</BuyBtn>
        </Link>
        <Link to={{ pathname: '/fund/' + fund.address, state: { status: 'redeem' } }}>
          <Btn>赎回</Btn>
        </Link>
      </ItemBtnBox>
    </DashbordItems>
  )
}
export function ItemLoading() {
  return (
    <LoadingBox>
      <CircularProgress color="inherit" />
      <LoadingText>加载中</LoadingText>
    </LoadingBox>
  )
}
