import { Position } from '@uniswap/v3-sdk'
import { BigNumber as BN } from 'ethers'
import BigNumber from 'bignumber.js'
import { usePool } from 'hooks/usePools'
import { useToken } from 'hooks/useToken'
import { useV3PositionFees } from 'hooks/useV3PositionFees'
import { useV3PositionFromTokenId } from 'hooks/useV3Positions'
import { useEffect, useMemo } from 'react'
import { useUpdateStrategyData } from 'state/strategy/hooks'
import { PositionDetails } from 'types/position'
import { unwrappedToken } from 'utils/unwrappedToken'

interface IProps {
  strategyAddress: string
  tokenId: string
}
export function PositionInfo({ strategyAddress, tokenId: tokenIdFromUrl }: IProps) {
  const parsedTokenId = tokenIdFromUrl ? BN.from(tokenIdFromUrl) : undefined
  const { loading, position: positionDetails } = useV3PositionFromTokenId(parsedTokenId)

  const {
    token0: token0Address,
    token1: token1Address,
    fee: feeAmount,
    liquidity,
    tickLower,
    tickUpper,
    tokenId,
  } = positionDetails || {}
  //   const removed = liquidity?.eq(0)
  const token0 = useToken(token0Address)
  const token1 = useToken(token1Address)

  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined

  // construct Position from details returned
  const [poolState, pool] = usePool(token0 ?? undefined, token1 ?? undefined, feeAmount)
  const position = useMemo(() => {
    if (pool && liquidity && typeof tickLower === 'number' && typeof tickUpper === 'number') {
      return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
    }
    return undefined
  }, [liquidity, pool, tickLower, tickUpper])
  const { onUpdateStrategyPosition } = useUpdateStrategyData()
  useEffect(() => {
    if (!token0 || !token1 || !position) return

    onUpdateStrategyPosition(strategyAddress, tokenIdFromUrl, {
      token0: {
        balance: new BigNumber(position.amount0.toExact()).times(10 ** token0.decimals).toString(),
        address: token0.address,
      },
      token1: {
        balance: new BigNumber(position.amount1.toExact()).times(10 ** token1.decimals).toString(),
        address: token1.address,
      },
    })
  }, [token0, token1, position])

  // fees
  //   const [feeValue0, feeValue1] = useV3PositionFees(pool ?? undefined, positionDetails?.tokenId, true)
  return null
}
