import { createReducer } from '@reduxjs/toolkit'
import { updateFunds, UpdatePositionList, UpdateUnderly } from './actions'
import { FUNDS } from '../../constants/funds'
import { getProps } from 'utils/commons'

export interface fundData {
  address: string
  decimals: number
  symbol: string | undefined
  fullName: string | undefined
  tokens: string[]
  fundBalance: string
  types: string[]
  fees: number[]
  assets: string
  totalSupply: string
  icon: string
  lup: string
}

interface FundDataState {
  funds: fundData[]
  positionList: TookenListIn[]
  underly: Underly[]
}

export interface FundUnderlyItem {
  name: string
  id: string
  decimals: number
  icon: string
  trend: string
  address: string
  priceUsd: number
  change24h: number
}

const initialState: FundDataState = {
  funds: FUNDS.map((item) => {
    return {
      address: item.fundAddress,
      decimals: item.decimals,
      symbol: '',
      fullName: '',
      tokens: [],
      fundBalance: '0',
      types: item.types,
      fees: [],
      assets: '',
      totalSupply: '',
      underlysDetail: [],
      icon: item.icon,
      lup: '0',
    }
  }),
  positionList: [],
  underly: [],
}
export interface TookenListIn {
  ContractAddress: string
  tokenId: string
  [tokenid: string]: any
}
export interface TokenDetials {
  token0: string
  token1: string
  amount0: string
  amount1: string
}
export interface Underly {
  address: string
  change24h: number
  decimals: number
  icon: string
  id: string
  name: string
  priceUsd: number
  trend: string
  underlyBalance: string
}

export default createReducer<FundDataState>(initialState, (builder) =>
  builder
    .addCase(updateFunds, (state, { payload: { fundsData } }) => {
      const { funds } = { ...state }

      const newFundIndex = [...funds].findIndex((element) => element.address === fundsData[0].address)
      const newFunds = [...funds]
      newFunds[newFundIndex] = fundsData[0]
      return { ...state, funds: newFunds }
    })
    .addCase(UpdatePositionList, (state, action) => {
      const { positionList } = action.payload
      return { ...state, positionList: positionList }
    })
    .addCase(UpdateUnderly, (state, action) => {
      const { underly } = action.payload
      return { ...state, underly: underly }
    })
)
