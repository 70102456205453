import { createReducer } from '@reduxjs/toolkit'
import { updateUserLocale, updateUserSingleHopOnly } from './actions'
import { SupportedLocale } from 'constants/locales'

const currentTimestamp = () => new Date().getTime()

export interface UserState {
  userLocale: SupportedLocale | null
  timestamp: number
  tokens?: any
  userSingleHopOnly: boolean
}

export const initialState: UserState = {
  userLocale: null,
  userSingleHopOnly: false,
  timestamp: currentTimestamp(),
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateUserLocale, (state, action) => {
      state.userLocale = action.payload.userLocale
      state.timestamp = currentTimestamp()
    })
    .addCase(updateUserSingleHopOnly, (state, action) => {
      state.userSingleHopOnly = action.payload.userSingleHopOnly
    })
)
