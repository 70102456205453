import React from 'react'

import { Box, ModalUnstyled } from '@material-ui/core'
import styled from 'styled-components/macro'

interface IProps {
  confirmStatus: boolean
  handleConfirmClose: () => void
  children?: React.ReactNode
}

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
`
const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
`

const CloseButton = styled.span`
  cursor: pointer;
  display: inline-block;
  textalign: right;
`
export default function TransactionConfirmationModal({ confirmStatus, handleConfirmClose, children }: IProps) {
  return (
    <>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={confirmStatus}
        onClose={handleConfirmClose}
        BackdropComponent={Backdrop}
      >
        <Box
          sx={{
            outline: 0,
            backgroundColor: '#fff',
            padding: '10px',
            boxShadow: 'rgb(47 128 237 / 5%) 0px 4px 8px 0px',
            width: '50vw',
            overflow: 'hidden auto',
            alignSelf: 'center',
            maxWidth: '420px',
            maxHeight: '90vh',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '20px',
          }}
        >
          <CloseButton onClick={handleConfirmClose}>X</CloseButton>
          {children}
        </Box>
      </StyledModal>
    </>
  )
}
