import styled from 'styled-components/macro'
import DetailBase from '../../components/FundDetail/DetailBase'
import DetailBalanceAndFees from '../../components/FundDetail/DetailBalanceAndFees'
import DetailBuyAndRedeem from '../../components/FundDetail/DetailBuyAndRedeem'
import DetailBuyAndRedeem2 from '../../components/FundDetail/DetailBuyAndRedeem2'
import DetailAssets from '../../components/FundDetail/DetailAssets'
import { FUNDS, FUNDS_ITEM } from '../../constants/funds'
import { RouteComponentProps } from 'react-router-dom'
import { isAddress } from '../../utils'
import DetailTradeChart from '../../components/FundDetail/DetailTradeChart'
import BigNumber from 'bignumber.js'
import { fundData } from '../../state/funds/reducer'
import { useUSDT } from '../../constants/tokens'
import { useWeb3React } from '@web3-react/core'

const FundDetailBox = styled.div`
  max-width: 1140px;
  box-sizing: border-box;
  border: 14px solid #f6f7f9;
  border-radius: 14px;
  margin: 60px 10px;
  padding: 30px;
  &:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  @media screen and (max-width: 1140px) {
    margin-top: 0;
    padding: 20px 10px;
  }
`
const DetailLeft = styled.div`
  width: 680px;
  border-right: 1px dashed #ddd;
  float: left;
  box-sizing: border-box;
  padding-right: 30px;
  @media screen and (max-width: 1140px) {
    width: 100%;
    border-right: none;
    padding-right: 0;
    margin-top: 20px;
  }
`
const DetailRight = styled.div`
  width: 370px;
  float: right;
  box-sizing: border-box;
  padding-left: 30px;
  @media screen and (max-width: 1140px) {
    float: left;
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }
`

export default function FundDetail({
  match: {
    params: { address: addressFromUrl },
  },
}: RouteComponentProps<{ address?: string }>) {
  const checkAddr = isAddress(addressFromUrl)
  const isFundAddr = checkAddr && FUNDS.find((item) => item.fundAddress.toLowerCase() === checkAddr.toLowerCase())
  return <>{isFundAddr ? <Fund address={checkAddr} fundsDatas={isFundAddr} /> : <p>地址错误</p>}</>
}
function Fund({ address, fundsDatas }: { address: string; fundsDatas: FUNDS_ITEM }) {
  return (
    <FundDetailBox>
      <DetailLeft>
        <DetailBase address={address} />
      </DetailLeft>
      <DetailRight>
        <DetailBalanceAndFees address={address} />
        {/* TODO: 当前只是为了尽快上线 如果要同时支持单币种与多币种 目前的想法是定义为一个类 然后实例化的时候有不同差异 */}
        {fundsDatas.edition === 'v2' ? (
          <DetailBuyAndRedeem address={address} />
        ) : (
          <DetailBuyAndRedeem2 address={address} />
        )}
      </DetailRight>
      <DetailLeft>
        <DetailAssets address={address} />
        <DetailTradeChart address={address} />
      </DetailLeft>
    </FundDetailBox>
  )
}
export function calcFundPrice(fundData: fundData) {
  // TODO: （鸡蛋里挑骨头），这里去判断assets和totalSupply是否存在貌似没有太大的必要？
  if (
    fundData.assets &&
    new BigNumber(fundData.assets).comparedTo(0) !== 0 &&
    fundData.totalSupply &&
    new BigNumber(fundData.totalSupply).comparedTo(0) !== 0
  ) {
    /**
     * TODO: 这里会存在2个问题：
     * 1. 这里如果资金分到了自动化合约，则计算出来的数值是不正确的
     * 2. assets是基金的总价值，totalSupply是基金的总发行量，目前这样写暂时正确的原因是assets（USDT精度是6）和totalSupply（刚好精度也是6）的精度是一样的，
     * 试想，如果assets精度是6，totalSupply精度是18，会发生什么？
     */
    return new BigNumber(fundData.assets).dividedBy(fundData.totalSupply).toFixed(fundData.decimals)
  } else {
    return new BigNumber(1).toFixed(fundData.decimals)
  }
}
