import styled from 'styled-components/macro'
import { Grid, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import MetamaskIcon from '../../assets/images/metamask.png'
import { useFundData, useFundsState } from '../../state/funds/hook'
import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { TOKENS, useUSDT, ITokenItem } from '../../constants/tokens'
import { default_chainId } from '../../connectors'
import { currencyFormat } from '../../utils/currencyAndAmountFormat'
import { getCoingeckoSimplePrice, getSimplePrice } from '../../apis/CoinGecko'
import { calcFundPrice } from '../../pages/FundDetail'

interface IUSDTData extends ITokenItem {
  priceUsd?: string
}
interface ISimplePrice {
  [symbol: string]: {
    usd: number
    usd_24h_change: number
  }
}
export interface ISimplePrice1 {
  [symbol: string]: {
    usd?: number
    usd_24h_change?: number
  }
}
const BaseBox = styled.div`
  width: 100%;
`
const FundSymbol = styled.span`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: #222222;
`
const FundTag = styled.span`
  background-color: rgba(43, 135, 140, 0.12);
  display: inline-block;
  padding: 2px 4px;
  color: #2b878c;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  border-radius: 2px;
  margin-left: 4px;
  vertical-align: top;
`
const FundName = styled.span`
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: #999999;
`
const AddToMetamask = styled(Grid)`
  display: inline-block;
  font-size: 12px;
  line-height: 22px;
  color: #222222;
  font-weight: 500;
  cursor: pointer;
  text-align: right;
  img {
    width: 24px;
    margin-right: 6px;
    margin-bottom: -2px;
    vertical-align: text-bottom;
  }
  @media screen and (max-width: 1140px) {
    text-align: left;
    margin-top: 8px;
  }
`
const PriceBox = styled.div`
  width: 100%;
  margin-top: 30px;
`
const PriceTitle = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: #888888;
  margin-bottom: 6px;
`
const FundPrice = styled.span`
  font-size: 32px;
  line-height: 32px;
  font-weight: 600;
  color: #222222;
  margin-right: 4px;
`
const FundUnit = styled.span`
  display: inline-block;
  border: 1px solid #222222;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 14px;
  color: #222222;
  font-weight: 400;
  margin-right: 20px;
  vertical-align: 6px;
`
const FundPriceUsd = styled.span`
  margin-top: 6px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: #222222;
`
const FundAum = styled.div`
  margin: 20px 0 0;
  font-size: 14px;
  line-height: 14px;
  .aum-title {
    font-weight: 500;
    color: #2b878c;
  }
  .aum-data {
    font-weight: 600;
    color: #222222;
  }
`
const FundIcon = styled.img`
  width: 38px;
`

export default function DetailBase({ address }: { address: string }) {
  const fundData = useFundData(address)
  const { chainId } = useWeb3React()
  const [fundPrice, setFundPrice] = useState<string>('')
  useEffect(() => {
    setFundPrice(calcFundPrice(fundData))
  }, [fundData])
  const usedChainId = chainId ?? default_chainId
  const usdtData: IUSDTData = TOKENS[useUSDT[usedChainId].address.toLowerCase()]
  useEffect(() => {
    getSimplePrice({ ids: 'tether' }).then((res: { data: ISimplePrice }) => {
      const simplePriceData: ISimplePrice = res.data
      if (usdtData) {
        usdtData.priceUsd = new BigNumber(simplePriceData[usdtData.id].usd).toFixed(2)
      }
    })
  }, [])

  const addToMetamask = async () => {
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      if (window.ethereum) {
        const wasAdded = await (window as any).ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: address,
              symbol: fundData.symbol,
              decimals: fundData.decimals,
              image: fundData.icon,
            },
          },
        })
        if (wasAdded) {
          console.log('Thanks for your interest!')
        } else {
          console.log('Your loss!')
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <BaseBox>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md={6}>
          <List sx={{ width: '100%', padding: 0 }} dense={true}>
            <ListItem sx={{ padding: 0 }}>
              <ListItemAvatar style={{ minWidth: 'auto', marginRight: '8px' }}>
                <FundIcon src={fundData.icon} alt="" />
              </ListItemAvatar>
              <ListItemText
                sx={{ margin: 0 }}
                primary={
                  <>
                    <FundSymbol>{fundData.symbol}</FundSymbol>
                    {fundData.types.map((type) => {
                      return <FundTag key={type}>{type}</FundTag>
                    })}
                  </>
                }
                secondary={<FundName>{fundData.fullName}</FundName>}
              />
            </ListItem>
          </List>
        </Grid>
        <AddToMetamask item xs={12} md={3} onClick={addToMetamask}>
          <img src={MetamaskIcon} alt="MetaMask" />
          添加到MetaMask
        </AddToMetamask>
      </Grid>
      <PriceBox>
        <PriceTitle>价格</PriceTitle>
        <FundPrice>{isNaN(Number(fundPrice)) ? '' : fundPrice}</FundPrice>
        <FundUnit>USDT</FundUnit>
        <br />
        <FundPriceUsd>
          {currencyFormat(new BigNumber(fundPrice).times(usdtData?.priceUsd ? usdtData.priceUsd : 0).toString())}
        </FundPriceUsd>
      </PriceBox>
      <FundAum>
        <span className="aum-title">AUM: </span>
        <span className="aum-data">
          {currencyFormat(
            new BigNumber(fundData.totalSupply)
              .dividedBy(10 ** fundData.decimals)
              .times(fundPrice)
              .times(usdtData?.priceUsd ? usdtData.priceUsd : 0)
              .toFixed(2)
          )}
        </span>
      </FundAum>
    </BaseBox>
  )
}
