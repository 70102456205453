import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { WalletDialog, WalletShow } from '../Wallet'
import styled from 'styled-components/macro'
interface IProps {
  children?: React.ReactNode
}
const LayoutContent = styled.div`
  padding-top: 75px;
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
`

export default function Layout({ children }: IProps) {
  return (
    <>
      <WalletDialog />
      <WalletShow />
      <Header />
      <LayoutContent>{children}</LayoutContent>
      <Footer />
    </>
  )
}
