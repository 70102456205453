import React from 'react'
import './App.css'
import { Route, Switch } from 'react-router-dom'
import Home from './Home'
import Layout from '../components/Layout'
import FundDetail from './FundDetail'
import UpdateFunds from '../components/FundDetail/UpdateFunds'

function App() {
  return (
    <Layout>
      <UpdateFunds />
      <Switch>
        <Route exact strict path="/" component={Home} />
        <Route exact strict path="/fund/:address" component={FundDetail} />
      </Switch>
    </Layout>
  )
}

export default App
