import styled from 'styled-components/macro'
import { useFundData } from '../../state/funds/hook'
import BigNumber from 'bignumber.js'
import { amountFormat } from '../../utils/currencyAndAmountFormat'

const BalanceBox = styled.div`
  width: 100%;
`
const BalanceTitle = styled.p`
  font-size: 14px;
  line-height: 14px;
  color: #888888;
  font-weight: 400;
  margin: 0;
`
const BalanceDetail = styled.span`
  margin: 2px 8px 0 0;
  font-size: 24px;
  font-weight: 600;
  color: #222222;
  cursor: pointer;
`
const BalanceUnit = styled.span`
  padding: 5px 13px;
  border-radius: 4px;
  border: 1px solid #222222;
  font-size: 14px;
  line-height: 14px;
  vertical-align: 4px;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: 4px;
`
const FeeBox = styled.div`
  padding: 13px 20px;
  border: 1px dashed #dddddd;
  margin-top: 20px;
  border-radius: 8px;
`
const FeeTitle = styled.p`
  margin: 0 0 20px 0;
  font-size: 14px;
  line-height: 14px;
  color: #222222;
  font-weight: 600;
`
const FeeSubTitle = styled.span`
  font-size: 12px;
  color: #666666;
`
const FeeItem = styled.p`
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 0;
  .number {
    float: right;
  }
  &:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
`

const FeeName: { [index: number]: string } = {
  [0]: '购买',
  [1]: '赎回',
  [2]: '基⾦管理费',
  [3]: '基⾦表现费',
  [4]: '交易流水费',
}

export default function DetailBalanceAndFees({ address }: { address: string }) {
  const fundData = useFundData(address)
  return (
    <>
      <BalanceBox>
        <BalanceTitle>余额</BalanceTitle>
        <BalanceDetail>
          {amountFormat(new BigNumber(fundData.fundBalance).dividedBy(10 ** fundData.decimals).toFixed())}
        </BalanceDetail>
        <BalanceUnit>{fundData.symbol}</BalanceUnit>
      </BalanceBox>
      <FeeBox>
        <FeeTitle>
          费用 <FeeSubTitle> (按百分比收取)</FeeSubTitle>
        </FeeTitle>
        {fundData.fees.map((item, index) => {
          return (
            <div key={`fee-${index}`}>
              {new BigNumber(item).comparedTo(0) !== 0 ? (
                <FeeItem>
                  {FeeName[index]}： <span className="number">{item}%</span>
                </FeeItem>
              ) : null}
            </div>
          )
        })}
      </FeeBox>
    </>
  )
}
