import { createAction } from '@reduxjs/toolkit'
import { SupportedLocale } from 'constants/locales'

export const updateUserLocale = createAction<{ userLocale: SupportedLocale }>('user/updateUserLocale')
export interface SerializedToken {
  chainId: number
  address: string
  decimals: number
  symbol?: string
  name?: string
}
export const updateMatchesDarkMode = createAction<{ matchesDarkMode: boolean }>('user/updateMatchesDarkMode')
export const updateUserSingleHopOnly = createAction<{ userSingleHopOnly: boolean }>('user/updateUserSingleHopOnly')
