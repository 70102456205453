import ERC20_ABI from 'abis/erc20.json'
import ERC20_BYTES32_ABI from 'abis/erc20_bytes32.json'
import WETH_ABI from 'abis/weth.json'
import { useActiveWeb3React } from './web3'
import { getContract } from '../utils'
import { useMemo } from 'react'
import { Contract } from '@ethersproject/contracts'
import { Erc20, Uo1FundAbi, Uo2FundAbi, Uo1StrategyAbi, Uo2StrategyAbi, Weth } from '../abis/types'
import { WETH9_EXTENDED } from '../constants/tokens'
import uo1_fundAbi from '../abis/uo1_fundAbi.json'
import uo2_fundAbi from '../abis/uo2_fundAbi.json'
import uo1_StrategyAbi from '../abis/uo1_StrategyAbi.json'
import uo2_StrategyAbi from '../abis/uo2_StrategyAbi.json'
import { Quoter, NonfungiblePositionManager, UniswapInterfaceMulticall } from 'types/v3'
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES, MULTICALL_ADDRESS, QUOTER_ADDRESSES } from 'constants/addresses'
import { abi as NFTPositionManagerABI } from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'
import { abi as MulticallABI } from '@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json'
import { abi as QuoterABI } from '@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json'

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, library, chainId, withSignerIfPossible, account]) as T
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}
export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}
export function useWETHContract(withSignerIfPossible?: boolean) {
  const { chainId } = useActiveWeb3React()
  return useContract<Weth>(chainId ? WETH9_EXTENDED[chainId]?.address : undefined, WETH_ABI, withSignerIfPossible)
}
export function useUo1FundContract(address: string | undefined, withSignerIfPossible?: boolean) {
  return useContract<Uo1FundAbi>(address, uo1_fundAbi, withSignerIfPossible)
}
export function useUo2FundContract(address: string | undefined, withSignerIfPossible?: boolean) {
  return useContract<Uo2FundAbi>(address, uo2_fundAbi, withSignerIfPossible)
}
export function useUo1StrategyContract(address: string | undefined) {
  return useContract<Uo1StrategyAbi>(address, uo1_StrategyAbi, true)
}
export function useUo2StrategyContract(address: string | undefined) {
  return useContract<Uo2StrategyAbi>(address, uo2_StrategyAbi, true)
}
export function useV3NFTPositionManagerContract(withSignerIfPossible?: boolean): NonfungiblePositionManager | null {
  return useContract<NonfungiblePositionManager>(
    NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
    NFTPositionManagerABI,
    withSignerIfPossible
  )
}
export function useMulticall2Contract() {
  return useContract<UniswapInterfaceMulticall>(MULTICALL_ADDRESS, MulticallABI, false) as UniswapInterfaceMulticall
}
export function useV3Quoter() {
  return useContract<Quoter>(QUOTER_ADDRESSES, QuoterABI)
}
