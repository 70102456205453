import { createReducer } from '@reduxjs/toolkit'
import { updateUnderly } from './actions'

// TODO 还是any？？？
export interface underlyData {
  positionList: any
  underlyList: any
}

const initialState: underlyData = {
  positionList: {},
  underlyList: {},
}

export default createReducer<underlyData>(initialState, (builder) =>
  builder.addCase(updateUnderly, (state, { payload: { underly } }) => {
    // 这里转换成字符串是因为Map不能序列化
    return { ...state, positionList: underly.positionList, underlyList: underly.underlyList }
  })
)
