import styled from 'styled-components/macro'
import ListBox from '../../components/ListBox'
import BoxLoading from '../../components/BoxLoad'
import { DashbordItem, ItemLoading } from '../../components/DashbordItem'
import { useFundsState } from '../../state/funds/hook'
import { useWeb3React } from '@web3-react/core'

const DashbordTitle = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 16px;
  color: #222222;
  font-weight: 500;
`
const DashbordBox = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
`
export default function Home() {
  const fundsData = useFundsState().funds
  const { active } = useWeb3React()

  return (
    <>
      <ListBox title="VIP托管产品">
        <div>
          <DashbordTitle>我的持仓</DashbordTitle>
          <DashbordBox>
            {fundsData.length === 0 && <BoxLoading />}
            {fundsData.map((item, index) =>
              item.symbol === '' ? (
                <ItemLoading key={'loading' + index.toString()}></ItemLoading>
              ) : (
                <DashbordItem key={item.address + index.toString()} fund={item} walletLocked={active}></DashbordItem>
              )
            )}
          </DashbordBox>
        </div>
      </ListBox>
    </>
  )
}
