import PositionListItem from './PositionListItem'
import React from 'react'
import { PositionDetails } from 'types/position'
import { useActiveWeb3React } from 'hooks/web3'
import { useV3NFTPositionManagerContract } from 'hooks/useContract'
import useTheme from '../../hooks/useTheme'
import { useV3Positions } from 'hooks/useV3Positions'

export type PositionListProps = React.PropsWithChildren<{
  address: string
}>

export default function PositionList({ address }: PositionListProps) {
  const { positions } = useV3Positions(address)
  const [openPositions] = positions?.reverse().reduce<[PositionDetails[], PositionDetails[]]>(
    (acc, p) => {
      acc[p.liquidity?.isZero() ? 1 : 0].push(p)
      return acc
    },
    [[], []]
  ) ?? [[], []]
  const filteredPositions = [...openPositions]

  // 提高效率，把PositionListItem里的弄出来了
  const theme = useTheme()
  const { chainId, account, library } = useActiveWeb3React()
  const positionManager = useV3NFTPositionManagerContract()
  const customPositionDetails = { theme, chainId, account, library, positionManager }
  console.log(filteredPositions)
  return (
    <>
      {filteredPositions.map((p) => {
        return (
          <PositionListItem
            key={p.tokenId.toString()}
            positionDetails={p}
            customPositionDetails={customPositionDetails}
            address={address}
          />
        )
      })}
    </>
  )
}
