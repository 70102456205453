import { Doughnut } from 'react-chartjs-2'
import styled from 'styled-components/macro'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import axios from 'axios'
import { CoinGeckoUrl } from '../../constants/tokens'
import { memo, useEffect, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { ITokenJson, useStrategyData } from '../../hooks/useStrategyData'
import { currencyFormat, percentFormat } from '../../utils/currencyAndAmountFormat'
import { getSimplePrice } from '../../apis/CoinGecko'

function DetailAssets({ address }: { address: string }) {
  const underlyData = useHandleUnderly(address)
  return (
    <>
      {underlyData && (
        <>
          <AssetsChart underlyData={underlyData} />
          <AssetsTable underlyData={underlyData} />
        </>
      )}
    </>
  )
}
export default memo(DetailAssets)

const ChartBox = styled.div`
  width: 420px;
  margin: 0 auto;
  @media screen and (max-width: 1140px) {
    width: 100%;
  }
`
interface UnderlyData extends ITokenJson {
  percent?: string
  amountUsd?: string
}
function AssetsChart({ underlyData }: { underlyData: UnderlyData[] }) {
  const [labels, setLabels] = useState<string[]>([])
  const [datas, setDatas] = useState<string[]>([])

  useEffect(() => {
    const [newLabels, newDatas] = underlyData.reduce<string[][]>(
      (t, item) => {
        t[0].push(item?.symbol ? item.symbol : item.name)
        t[1].push(new BigNumber(item?.percent ? item.percent : 0).times(100).toFixed())
        return t
      },
      [[], []]
    )
    setLabels(newLabels)
    setDatas(newDatas)
  }, [underlyData])
  const data = {
    labels: labels,
    datasets: [
      {
        data: datas,
        backgroundColor: ['#5856D6', '#FF9500', '#FF3B30', '#007AFF', '#4CD9CA', '#7ED321', '#C7C7CC', '#424A5F'],
      },
    ],
  }
  const options = {
    animation: false,
    plugins: {
      animation: {
        animateRotate: false,
      },
      legend: {
        position: 'left',
      },
    },
  }
  return (
    <ChartBox>
      <Doughnut data={data} options={options} height={300} width={300} />
    </ChartBox>
  )
}
function useHandleUnderly(address: string) {
  const underlyData = useStrategyData(address)
  return useMemo(() => {
    if (!underlyData) return
    const underlyData_: UnderlyData[] = [...underlyData]
    let totalAmount = '0'

    underlyData_.forEach((item, index) => {
      let amountUsd = ''
      if (item?.priceUsd) {
        amountUsd = new BigNumber(item.amount).times(item.priceUsd).toFixed()
        underlyData_[index]['amountUsd'] = amountUsd
        totalAmount = new BigNumber(amountUsd).plus(totalAmount).toFixed()
      }
    })

    underlyData_.forEach((item, index) => {
      // if (item?.underlyBalance) {
      //   amountUsd = new BigNumber(item.amount).plus(item.underlyBalance).times(item.priceUsd).toFixed()
      // } else {
      // }
      if (item?.priceUsd) {
        underlyData_[index]['percent'] = new BigNumber(item?.amountUsd ? item.amountUsd : 0)
          .dividedBy(totalAmount)
          .toString()
      }
    })

    return underlyData_
  }, [underlyData])
}
function AssetsTable({ underlyData }: { underlyData: UnderlyData[] }) {
  console.log(underlyData, 'underlyData===================')
  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: 648 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>持有</TableCell>
            <TableCell align="right">价格</TableCell>
            <TableCell align="right">分配比例</TableCell>
            <TableCell align="right">24h</TableCell>
            <TableCell align="right">涨跌幅(7d)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {underlyData.map((item, index) => {
            return (
              <TableRow key={item.name + index.toString()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <img src={item.icon} alt="" height="28px" style={{ verticalAlign: 'middle', marginRight: 4 }} />
                  {item.symbol || item.name}
                </TableCell>
                <TableCell align="right">{currencyFormat(item.priceUsd)}</TableCell>
                <TableCell align="right">{percentFormat(item?.percent ? item.percent : 0)}%</TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color:
                      new BigNumber(item?.change24h ? item.change24h : '0').comparedTo(0) !== -1
                        ? '#00b464'
                        : '#fa4d56',
                  }}
                >
                  {new BigNumber(item?.change24h ? item.change24h : '0').comparedTo(0) !== -1 ? '+' : ''}
                  {new BigNumber(item?.change24h ? item.change24h : '0').toFixed(2)}%
                </TableCell>
                <TableCell align="right">
                  <img src={item.trend} alt="" height="28px" />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
