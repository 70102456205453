import { createChart, HistogramData, IChartApi, ISeriesApi } from 'lightweight-charts'
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { getFormattedTimeString } from '../../utils/calculateTime'

const ChartBox = styled.div`
  margin-top: 30px;
  width: 100%;
`
interface chartDataInterface {
  ts: number
  tradeVolume: number
  price: number
  close: number
  open: number
}

export default function DetailTradeChart({ address }: { address: string }) {
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const chart = useRef<IChartApi>()
  const [areaSeries, setAreaSeries] = useState<ISeriesApi<'Area'>>()
  const [histogramSeries, setHistogramSeries] = useState<ISeriesApi<'Histogram'>>()
  const [chartData, setChartData] = useState<chartDataInterface[]>([])
  const [volumeData, setVolumeData] = useState<HistogramData[]>()
  const [areaData, setAreaData] = useState<HistogramData[]>()
  useEffect(() => {
    // TODO: any赋值到chartDataInterface[]，不担心可能的问题吗？(已修改)
    getChartData().then((data) => {
      const chartData: chartDataInterface[] = data.data
      setChartData(chartData)
    })
  }, [])
  const getChartData = async () => {
    return await axios.get(`https://vip.uniocean.org/beast/chart/${getTimeZone()}/${address}`)
  }
  const getTimeZone = () => {
    const timeZone = 0 - new Date().getTimezoneOffset() / 60
    return timeZone >= 0 ? `+${timeZone}` : timeZone
  }
  useEffect(() => {
    chart.current = createChart(chartContainerRef.current as HTMLElement, {
      width: 660,
      height: 300,
      rightPriceScale: {
        scaleMargins: {
          top: 0.3,
          bottom: 0.25,
        },
        borderVisible: false,
      },
      layout: {
        backgroundColor: '#ffffff',
        textColor: '#dddddd',
      },
      grid: {
        vertLines: {
          color: '#dddddd',
        },
        horzLines: {
          color: '#dddddd',
        },
      },
      localization: {
        locale: 'en-US',
      },
    })
    const areaSeries = chart.current.addAreaSeries({
      topColor: 'rgba(255,255,255, 0.04)',
      bottomColor: 'rgba(255,255,255, 0.04)',
      lineColor: '#aaaaaa',
      lineWidth: 2,
    })
    setAreaSeries(areaSeries)
    const volumeSeries = chart.current.addHistogramSeries({
      color: '#26a69a',
      priceFormat: {
        type: 'volume',
      },
      priceScaleId: '',
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
    })
    setHistogramSeries(volumeSeries)
  }, [])
  useEffect(() => {
    const areaSeriesData: HistogramData[] = []
    const volumeSeriesData: HistogramData[] = []
    chartData.forEach((item) => {
      areaSeriesData.push({ time: getFormattedTimeString(item.ts, 'yyyy-MM-DD'), value: item.price })
      volumeSeriesData.push({
        time: getFormattedTimeString(item.ts, 'yyyy-MM-DD'),
        value: item.tradeVolume,
        color: item.close >= item.open ? '#00B464' : '#FA4D56',
      })
    })
    setVolumeData(volumeSeriesData)
    setAreaData(areaSeriesData)
  }, [chartData])
  useEffect(() => {
    if (!volumeData || !areaData) {
      return
    }
    areaSeries?.setData(areaData)
    histogramSeries?.setData(volumeData)
  }, [areaSeries, histogramSeries, volumeData, areaData])
  return (
    <>
      <ChartBox ref={chartContainerRef} />
    </>
  )
}
