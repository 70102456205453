import { configureStore } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import user from './user/reducer'
import funds from './funds/reducer'
import multicall from './multicall/reducer'
import list from './lists/reducer'
import transactions from './transactions/reducer'
import application from './application/reducer'
import pools from './pools/reducer'
import positionRemind from './positionRemind/reducer'
import settings from './settings/reducer'
import tokens from './tokens/reducer'
import underly from './underly/reducer'
import logs from './logs/slice'
import wallet from './wallet/reducer'
import strategy from './strategy/reducer'

const PERSISTED_KEYS: string[] = ['user', 'tokens', 'strategy']

const store = configureStore({
  reducer: {
    user,
    transactions,
    funds,
    multicall,
    list,
    logs,
    application,
    pools,
    positionRemind,
    settings,
    tokens,
    wallet,
    underly,
    strategy,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true }).concat(save({ states: PERSISTED_KEYS, debounce: 1000 })),
  preloadedState: load({ states: PERSISTED_KEYS }),
})

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
