import { createReducer } from '@reduxjs/toolkit'
import { updateChooseWallet, updateShowInfo } from './actions'

export interface walletDataState {
  showWalletChoose: boolean
  showWalletInfo: boolean
}

const initialState: walletDataState = {
  showWalletChoose: false,
  showWalletInfo: false,
}

export default createReducer<walletDataState>(initialState, (builder) =>
  builder
    .addCase(updateChooseWallet, (state, { payload: { showWalletChoose } }) => {
      // 这里转换成字符串是因为Map不能序列化
      return { ...state, showWalletChoose: showWalletChoose }
    })
    .addCase(updateShowInfo, (state, { payload: { showWalletInfo } }) => {
      // 这里转换成字符串是因为Map不能序列化
      return { ...state, showWalletInfo: showWalletInfo }
    })
)
