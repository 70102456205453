import { Button, Divider, Grid, Tab, Tabs, TextField, Snackbar, Alert, CircularProgress } from '@material-ui/core'
import arrowUpCircle from 'assets/svg/arrow-up-circle.svg'
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useUo1FundContract, useTokenContract } from '../../hooks/useContract'
import { useStrategyData } from '../../hooks/useStrategyData'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useFundData } from '../../state/funds/hook'
import Loader from '../Loader'
import { MaxUint256 } from '@ethersproject/constants'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { useUpdateWallet } from '../../state/wallet/hook'
import { useAppSelector } from '../../state/hooks'
import { useUSDT } from '../../constants/tokens'
import useDebounce from '../../hooks/useDebounce'
import TransactionConfirmationModal from '../TransactionConfirmationModal'
import { FUNDS } from '../../constants/funds'
import { amountFormat, currencyFormat } from '../../utils/currencyAndAmountFormat'
import { getSimplePrice } from '../../apis/CoinGecko'
import { getFormattedTimeString } from '../../utils/calculateTime'
import { calcFundPrice } from '../../pages/FundDetail'

interface priceData {
  [k: string]: {
    usd: number
    usd_24h_change: number
  }
}

const OperateTabs = styled(Tabs)`
  margin-top: 30px;
  @media screen and (max-width: 1140px) {
    margin-top: 20px;
  }
  .MuiTabs-indicator {
    background-color: #2b878c;
  }
`
const OperateTab = styled(Tab)`
  font-weight: 600;
  font-size: 16px;
  .MuiTab-wrapper {
    align-items: flex-start;
    position: relative;
    color: #bbbbbb !important;
    padding-left: 4px;
    &:before {
      content: '';
      position: absolute;
      background-color: #bbbbbb;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      left: -10px;
    }
  }
  &.Mui-selected {
    .MuiTab-wrapper {
      color: #2b878c !important;
      &:before {
        background-color: #2b878c;
      }
    }
  }
`
const TabBox = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  border: 1px dashed #222222;
  box-sizing: border-box;
  padding: 20px;
  margin-top: 20px;
`

export default function DetailBuyAndRedeem({ address }: { address: string }) {
  const [operate, setOperate] = useState(0)
  const changeOperate = (event: SyntheticEvent, newValue: number) => {
    setOperate(newValue)
  }
  return (
    <>
      <OperateTabs value={operate} onChange={changeOperate} variant="fullWidth">
        <OperateTab label="购买" />
        <OperateTab label="赎回" />
      </OperateTabs>
      {operate === 0 ? (
        <TabBox>
          <ToBuy address={address} />
        </TabBox>
      ) : (
        <TabBox>
          <ToRedeem address={address} />
        </TabBox>
      )}
    </>
  )
}

const TabTitle = styled.p`
  font-size: 14px;
  line-height: 14px;
  color: #222222;
  font-weight: 600;
  margin: 0 0 20px;
`
const TabBalance = styled.p`
  margin: 0 0 8px;
  .balance {
    cursor: pointer;
    color: #2b878c;
  }
`
const Unit = styled.span`
  height: 24px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #222;
  margin: 0;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px 8px;
  font-size: 14px;
  line-height: 14px;
`
const AmountTitle = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: #222;
  font-weight: 400;
  display: inline-block;
  width: 100%;
  margin-bottom: 6px;
  margin-top: 20px;
`
const AmountNum = styled.span`
  margin-top: 4px;
  font-size: 22px;
  line-height: 22px;
  color: #222;
  font-weight: 600;
`
const InputError = styled.span`
  color: #eb5654;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 16px;
  height: 22px;
  display: inline-block;
`
const AwaitP = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin: 0px 0px 12px;
`
const TopicP = styled(AwaitP)`
  font-weight: 50;
  font-size: 12px;
  color: rgb(86, 90, 105);
`

function ToBuy({ address }: { address: string }) {
  const { account, library, chainId } = useWeb3React()
  const fundData = useFundData(address)
  const [iptVal, setIptVal] = useState<string>('0')
  const [userBalance, setUserBalance] = useState<string>(new BigNumber(0).toFixed(6))
  const [buyAmount, setBuyAmount] = useState<string>(new BigNumber(0).toFixed(6))
  const [btnState, setBtnState] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [inputErr, setInputErr] = useState<null | string>(null)
  const [isInitIpt, setIsInitIpt] = useState(true)
  const fundContract = useUo1FundContract(address)
  const debounceVal = useDebounce(iptVal, 300)
  const [modalStatus, setModalStatus] = useState<boolean>(false)
  const [confirmStatus, setConfirmStatus] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertState, setAlertState] = useState(0)
  const [mint, setMint] = useState<undefined | string>(undefined)
  const underlyData = useStrategyData(address)
  const [Eth2Usd, setEth2Usd] = useState<string | undefined>('')
  useEffect(() => {
    // TODO 不toLower??? filter如果找不到呢？setEth2Usd分别是正常string、''以及undefined的时候会如何处理？ string是真的有点模糊不清，不如用number来得好
    // 已修改 string如果为空字符串Boolean也为false 与undefined一样 如果这边修改为string改动会有点大 这个周一和强哥讨论后来看看是否修改
    const eth2Usd = underlyData && underlyData.filter((item) => item.id.toLowerCase() === 'weth')[0]?.priceUsd

    setEth2Usd(eth2Usd)
  }, [underlyData])
  useEffect(() => {
    setIsInitIpt(false)
    checkAmout(iptVal)
  }, [debounceVal])

  const closeAlert = () => {
    setShowAlert(false)
  }
  // TODO 之前说的，难道find不香么？要是找不到不又有问题了？
  // 已修改
  const fundsdatas = FUNDS.find((item) => item.fundAddress.toLowerCase() === address.toLowerCase())

  useEffect(() => {
    if (Number(iptVal) < 0) {
      return
    }
    if (fundsdatas) {
      if (new BigNumber(iptVal).comparedTo(fundsdatas.minPurchase) < 0 && new BigNumber(iptVal).comparedTo(0) !== 0) {
        setInputErr(`最少购买${fundsdatas.minPurchase}USDT`)
      } else if (new BigNumber(iptVal).comparedTo(userBalance) === 1) {
        setInputErr('余额不足')
      } else {
        setInputErr(null)
      }
    }

    // setIsInitIpt(false)
  }, [iptVal, userBalance])
  const checkAmout = (val: string) => {
    if (!fundsdatas || !Boolean(val) || Number(val) < 0) {
      return
    }
    try {
      const fundPrice = calcFundPrice(fundData)
      // TODO 这个计算是有问题的吧！用户输入的usdt数量除以基金净值才对啊！不是乘！另外如果val不是数字的字符串会触发catch么？
      //已修改  如果不是数字的字符串不会触发catch 但是走不到这个方法  因为前面输入框做了限制只能输入数字  这一块整个是否改为number和上面那个TODO一样 如果上面那个要修改这一整块都会改为number
      setBuyAmount(
        amountFormat(new BigNumber(val).dividedBy(fundPrice).toFixed()) ?? new BigNumber(0).toFixed(fundsdatas.decimals)
      )
    } catch (e) {
      console.error(e)
      setBuyAmount(new BigNumber(0).toFixed(fundsdatas.decimals))
    }
  }
  const usdtAddr = chainId ? useUSDT[chainId].address : undefined
  const usdtContract = useTokenContract(usdtAddr, true)
  useEffect(() => {
    if (Number(iptVal) < 0) {
      return
    }

    if (!account) {
      setBtnState(0)
      setUserBalance(new BigNumber(0).toFixed(6))
    } else {
      usdtContract
        ?.balanceOf(account)
        .then((res) => {
          setUserBalance(new BigNumber(res.toString()).dividedBy(10 ** 6).toFixed(6))
          getAllowance()
        })
        .catch((e) => console.error(e))
    }
  }, [account, debounceVal])
  const getAllowance = () => {
    try {
      if (account && chainId) {
        usdtContract?.allowance(account, address).then(async (res) => {
          const allowance = new BigNumber(res.toString())
          // TODO:多币种时修改
          if (
            (allowance.comparedTo(0) === 0 || allowance.comparedTo(new BigNumber(iptVal).times(10 ** 6)) < 0) &&
            Number(debounceVal) > 0
          ) {
            setBtnState(1)
            // TODO 如果这里debounceVal的值为负数会怎么样？另外gasPrice拼错了
            // 已修改 暂时做处理如果为0或者小于0就不进这个方法 否则会报错
            const estimateGas = await usdtContract.estimateGas.approve(address, debounceVal)
            const calcGas = calculateGasMargin(chainId, estimateGas).toString()
            const gasPrice = await library.getGasPrice()
            const mint = new BigNumber(calcGas)
              .times(gasPrice.toString())
              .dividedBy(10 ** 18)
              .toString()
            setMint(mint)
          } else {
            setBtnState(2)
            if (debounceVal === '0' || debounceVal === '') {
              return
            }
            const estimateGas = await fundContract!.estimateGas.joinPool(
              new BigNumber(debounceVal).times(10 ** 6).toFixed()
            )
            const calcGas = calculateGasMargin(chainId, estimateGas).toString()
            const gasPrice = await library.getGasPrice()
            const mint = new BigNumber(calcGas)
              .times(gasPrice.toString())
              .dividedBy(10 ** 18)
              .toString()
            setMint(mint)
          }
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
  const { onUpdateChooseWallet } = useUpdateWallet()
  const unlockWallet = () => {
    onUpdateChooseWallet(true)
  }

  // TODO 前面没有注释和说明，这里0，1，2的状态实在是看不懂，这里还有设置3的值，但是switch中并没有体现，然后甚至不知道是否有4、5、6
  // 0 解锁 1 授权&购买  2 购买 3 赎回 在此只有0 1 2 三种状况 赎回是在另一个组件中 在这写为数字是因为下面都调用了封闭期组件 以此数字作为的索引值
  const handleClick = async () => {
    switch (btnState) {
      case 0:
        unlockWallet()
        break
      case 1:
      case 2:
        handleBuy()
        break
      // 下面的的代码注释给一个小技巧，如果switch把所有可能情况都覆盖完了，在default里可以用我写的这一句，这样在以后增加比如4，5，6情况的时候，代码在编译阶段就会给你报错提示，这样在需求增加导致增加一种情况的时候看到报错信息就很一目了然，也不会漏。所以这里用字面量类型会更加好
      // 如果打开这一句 就算情况覆盖完了ts检查类型时也会报错
      // default:
      //   const _exhaustiveCheck: never = btnState
    }
  }
  const approve = async (num?: string) => {
    if (usdtContract && chainId) {
      try {
        const amount = num ?? MaxUint256
        const estimateGas = await usdtContract.estimateGas.approve(address, amount)
        const tx = await usdtContract.approve(address, amount, {
          gasLimit: calculateGasMargin(chainId, estimateGas),
        })
        return await tx.wait()
      } catch (err) {
        console.error(err, 'approve的err')
        setIsLoading(false)
        return null
      }
    }
    return null
  }

  const handleBuy = async () => {
    // await approve('0')
    if (inputErr || isLoading) return
    if (new BigNumber(iptVal).comparedTo(0) === 0) {
      setInputErr('请输入购买金额')
      return
    }
    setIsLoading(true)
    setModalStatus(true)
    if (account) {
      usdtContract?.allowance(account, address).then(async (res) => {
        const allowance = new BigNumber(res.toString())
        if (allowance.comparedTo(0) === 0) {
          const approveRes = await approve()
          if (approveRes) {
            await handleBuy()
          }
          // TODO:多币种时修改
        } else if (allowance.comparedTo(new BigNumber(iptVal).times(10 ** 6)) !== -1) {
          await toBuy()
        } else {
          const approveRes = await approve('0')
          if (approveRes) {
            await handleBuy()
          }
        }
      })
    }
  }
  const toBuy = async () => {
    if (fundContract && chainId) {
      try {
        const estimateGas = await fundContract.estimateGas.joinPool(new BigNumber(iptVal).times(10 ** 6).toFixed())
        const tx = await fundContract.joinPool(new BigNumber(iptVal).times(10 ** 6).toFixed(), {
          gasLimit: calculateGasMargin(chainId, estimateGas),
        })
        setModalStatus(false)
        setConfirmStatus(true)
        const txData = await tx.wait()
        if (txData.status === 1) {
          setShowAlert(true)
          setAlertState(0)
          setIptVal('0')
          getAllowance()
          setIsInitIpt(true)
        } else {
          // setShowAlert(true)
          setAlertState(1)
        }
        setIsLoading(false)
      } catch (err) {
        console.error(err, 'tobuy函数的err')
        setIsLoading(false)
        setModalStatus(false)
      }
    }
  }
  const handleClose = () => {
    setModalStatus(false)
    setConfirmStatus(false)
  }
  return (
    <>
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={closeAlert}
      >
        <Alert onClose={closeAlert} severity={alertState === 0 ? 'success' : 'error'} sx={{ width: '100%' }}>
          {alertState === 0 ? '交易成功' : '交易失败'}
        </Alert>
      </Snackbar>
      <TabTitle>购买</TabTitle>
      <TabBalance>
        余额： {/* TODO 之前写了方法这里不用？？？这里固定6迟早是个隐患 */}
        {/* 已修改 */}
        <span className="balance" onClick={() => setIptVal(userBalance)}>
          {amountFormat(userBalance)}
        </span>
      </TabBalance>
      <TransInputLine val={iptVal} handleChange={(e) => setIptVal(e.target.value)} />
      <InputError>{inputErr && !isInitIpt ? inputErr : '  '}</InputError>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          <AmountTitle>数量</AmountTitle>
        </Grid>
        <Grid item xs={7}>
          <AmountNum>{buyAmount}</AmountNum>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right' }}>
          <Unit>{fundData.symbol}</Unit>
        </Grid>
      </Grid>
      <TransBtn typeNum={btnState} isLoading={isLoading} clickFunc={handleClick} lup={fundData.lup} />
      {mint && Eth2Usd ? (
        <span>预估矿工费: {currencyFormat(new BigNumber(mint).times(Eth2Usd).toFixed(2))}</span>
      ) : null}
      <TransDialog modalStatus={modalStatus} confirmStatus={confirmStatus} handleClose={handleClose} />
    </>
  )
}
const PercentTag = styled.span`
  width: 100%;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px 0;
  font-size: 12px;
  color: #999;
  line-height: 12px;
  cursor: pointer;
  display: inline-block;
  &.active {
    border-color: #2b878c;
    color: #2b878c;
  }
`
const RedeemTabBox = styled.div`
  width: 100%;
  .tabs {
    width: 100%;
    height: 26px;
    box-sizing: border-box;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    .tab {
      width: 51%;
      height: 26px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background: #f6f7f9;
      cursor: pointer;
      text-align: center;
      position: absolute;
      font-size: 14px;
      line-height: 26px;
      font-weight: 600;
      color: #222;
      &.active {
        background-color: #f6f7f9;
        border-bottom: 26px solid #2b878c;
        z-index: 1;
        color: #fff;
      }
      &:first-child {
        left: 0;
        &.active {
          border-right: 8px solid transparent;
        }
      }
      &:last-child {
        right: 0;
        &.active {
          border-left: 8px solid transparent;
        }
      }
    }
  }
  .tabs-content {
    width: 100%;
    box-shadow: 0 0 10px 5px #f6f7f9;
    border-radius: 0 0 8px 8px;
    padding: 20px 12px;
    box-sizing: border-box;
  }
`
const RedeemUnderlyItem = styled(Grid)`
  padding-bottom: 20px;
  .amount {
    font-size: 24px;
    line-height: 24px;
    color: #222;
    font-weight: 600;
  }
  .price {
    font-size: 14px;
    display: inline-block;
    width: 100%;
    margin-top: 4px;
    color: #222;
    opacity: 0.6;
  }
`
const RedeemUnderlyTotal = styled(Grid)`
  padding-top: 20px;
  font-size: 14px;
  line-height: 14px;
  .title {
    color: #999;
    font-weight: 400;
  }
  .num {
    font-weight: 600;
    color: #222;
  }
`
const ArrowCircleUpIcon = styled.img`
  width: 90px;
  height: 90px;
`
function ToRedeem({ address }: { address: string }) {
  const { account, active, chainId } = useWeb3React()
  const [iptVal, setIptVal] = useState<string>('0')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const fundData = useFundData(address)
  const [fundBalance, setFundBalance] = useState(fundData.fundBalance)
  const [tagNum, setTagNum] = useState<null | number>(null)
  const [btnState, setBtnState] = useState<number>(0)
  const [showUnderly, setShowUnderly] = useState<boolean>(false)
  const [inputErr, setInputErr] = useState<null | string>(null)
  const fundContract = useUo1FundContract(address)
  const [sellAmout, setSellAmount] = useState(new BigNumber(0).toFixed(6))
  const [showAlert, setShowAlert] = useState(false)
  const [alertState, setAlertState] = useState(0)
  const [percent, setPercent] = useState('0')
  const [totalUsd, setTotalUsd] = useState('0')
  const [modalStatus, setModalStatus] = useState<boolean>(false)
  const [confirmStatus, setConfirmStatus] = useState<boolean>(false)
  // TODO 这么大的any竟然视而不见！
  // 已修改
  const [priceData, setPriceData] = useState<priceData>()
  const closeAlert = () => {
    setShowAlert(false)
  }
  const fundsdatas = FUNDS.filter((item) => item.fundAddress.toLowerCase() === address.toLowerCase())[0]

  const underlyState = useAppSelector((state) => state.underly)

  const state = useAppSelector((state) => state.funds)
  useEffect(() => {
    setFundBalance(fundData.fundBalance)
  }, [fundData])
  useEffect(() => {
    if (account) {
      setBtnState(3)
    } else {
      setBtnState(0)
    }
  }, [account])
  useEffect(() => {
    if (Number(iptVal) < 0) {
      return
    }

    if (new BigNumber(iptVal).times(10 ** fundsdatas.decimals).comparedTo(fundBalance) === 1) {
      setInputErr('余额不足')
    } else {
      setInputErr(null)
    }
    checkAmount(iptVal)
  }, [iptVal, fundData])
  const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIptVal(event.target.value)
  }
  const checkAmount = async (val: string) => {
    try {
      if (fundContract && val) {
        const convertToShare = await fundContract.convertToCash(
          new BigNumber(val).times(10 ** fundsdatas.decimals).toNumber()
        )
        setSellAmount(
          new BigNumber(convertToShare.toString()).dividedBy(10 ** fundsdatas.decimals).toFixed(fundsdatas.decimals)
        )
      } else {
        setSellAmount(new BigNumber(0).toFixed(fundsdatas.decimals))
      }
    } catch (e) {
      console.error(e)
    }
  }

  const { onUpdateChooseWallet } = useUpdateWallet()
  const unlockWallet = () => {
    onUpdateChooseWallet(true)
  }
  const handleClick = async () => {
    switch (btnState) {
      case 0:
        unlockWallet()
        break
      case 3:
        if (isLoading) return
        if (inputErr) return
        setIsLoading(true)
        setModalStatus(true)

        if (showUnderly) {
          await redeemUnderly()
          setIsLoading(false)
          setModalStatus(false)
        } else {
          await redeem()
          setIsLoading(false)
          setModalStatus(false)
        }
        setIsLoading(false)
        break
    }
  }
  const checkBalancePercent = (percent: string, tagNum: number) => {
    setPercent(percent)
    const val = new BigNumber(fundData.fundBalance)
      .times(percent)
      .dividedBy(10 ** fundsdatas.decimals)
      .toString()
    setIptVal(new BigNumber(val).toFixed(fundsdatas.decimals))
    setTagNum(tagNum)
  }
  const redeem = async () => {
    if (fundContract && chainId) {
      try {
        const estimateGas = await fundContract.estimateGas.exitPool(
          new BigNumber(iptVal).times(10 ** fundsdatas.decimals).toFixed()
        )
        const tx = await fundContract.exitPool(new BigNumber(iptVal).times(10 ** fundsdatas.decimals).toFixed(), {
          gasLimit: calculateGasMargin(chainId, estimateGas),
        })
        setModalStatus(false)
        setConfirmStatus(true)
        const txData = await tx.wait()
        if (txData.status === 1) {
          // setShowAlert(true)
          setIptVal('0')
          setAlertState(0)
        } else {
          // setShowAlert(true)
          setAlertState(1)
        }
      } catch (err) {
        console.error(err)
        setIsLoading(false)
        setModalStatus(true)
      }
    }
    // return fundContract?.exitPool(new BigNumber(iptVal).times(10 ** 6).toFixed())
  }
  const redeemUnderly = async () => {
    if (fundContract && chainId) {
      try {
        const estimateGas = await fundContract.estimateGas.exitPoolOfUnderlying(
          new BigNumber(iptVal).times(10 ** fundsdatas.decimals).toFixed()
        )
        const tx = await fundContract.exitPoolOfUnderlying(
          new BigNumber(iptVal).times(10 ** fundsdatas.decimals).toFixed(),
          {
            gasLimit: calculateGasMargin(chainId, estimateGas),
          }
        )
        setModalStatus(false)
        setConfirmStatus(true)
        const txData = await tx.wait()
        if (txData.status === 1) {
          // setShowAlert(true)
          setAlertState(0)
        } else {
          // setShowAlert(true)
          setAlertState(1)
        }
      } catch (err) {
        console.error(err)
        setIsLoading(false)
        setModalStatus(false)
      }
    }
    // return await fundContract?.exitPoolOfUnderlying(new BigNumber(iptVal).times(10 ** 6).toFixed())
  }
  const underlyDatas = useStrategyData(address)

  const getPrice = async () => {
    if (!priceData) {
      const ids = underlyDatas && underlyDatas.map((token) => token.id).join(',')
      const tokensPrice = await getSimplePrice({ ids })

      setPriceData(tokensPrice.data)
    }
  }
  getPrice()
  useMemo(async () => {
    let totals = '0'
    console.log(underlyDatas, 'underlyDatas')

    const totalData =
      underlyDatas &&
      underlyDatas.map((item) => {
        if (item?.priceUsd) {
          totals = new BigNumber(item.amount).times(new BigNumber(item.priceUsd)).plus(new BigNumber(totals)).toFixed(2)
        }

        return totals
      })

    if (fundContract && totalData.length > 0) {
      const amountTotal = await fundContract.totalSupply()

      const totalAmount = new BigNumber(amountTotal.toString()).dividedBy(10 ** fundsdatas.decimals)

      const percent = new BigNumber(iptVal ? iptVal : 0).dividedBy(totalAmount).toString()
      setPercent(percent)
      totals = new BigNumber(totalData[totalData.length - 1]).times(new BigNumber(percent)).toFixed(2)
    }

    setTotalUsd(totals)
  }, [percent, iptVal])
  const handleClose = () => {
    setModalStatus(false)
    setConfirmStatus(false)
  }

  return (
    <>
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={closeAlert}
      >
        <Alert onClose={closeAlert} severity={alertState === 0 ? 'success' : 'error'} sx={{ width: '100%' }}>
          {alertState === 0 ? '交易成功' : '交易失败'}
        </Alert>
      </Snackbar>
      <TabTitle>数量</TabTitle>
      <TransInputLine val={iptVal} handleChange={inputChange} currency={fundData.symbol} />
      <Grid container direction="row" justifyContent="space-between" alignItems="center" marginTop="6px">
        <Grid item container xs={8} spacing={1}>
          <Grid item xs={3}>
            <PercentTag className={tagNum === 0 ? 'active' : ''} onClick={() => checkBalancePercent('0.25', 0)}>
              25%
            </PercentTag>
          </Grid>
          <Grid item xs={3}>
            <PercentTag className={tagNum === 1 ? 'active' : ''} onClick={() => checkBalancePercent('0.5', 1)}>
              50%
            </PercentTag>
          </Grid>
          <Grid item xs={3}>
            <PercentTag className={tagNum === 2 ? 'active' : ''} onClick={() => checkBalancePercent('0.75', 2)}>
              75%
            </PercentTag>
          </Grid>
          <Grid item xs={3}>
            <PercentTag className={tagNum === 3 ? 'active' : ''} onClick={() => checkBalancePercent('1', 3)}>
              100%
            </PercentTag>
          </Grid>
        </Grid>
      </Grid>
      <InputError>{inputErr ?? '  '}</InputError>
      <RedeemTabBox>
        <div className="tabs">
          <div className={showUnderly ? 'tab' : 'tab active'} onClick={() => setShowUnderly(!showUnderly)}>
            USDT
          </div>
          <div className={showUnderly ? 'tab active' : 'tab'} onClick={() => setShowUnderly(!showUnderly)}>
            Underlying
          </div>
        </div>
        <div className="tabs-content">
          {showUnderly ? (
            <>
              {underlyDatas &&
                underlyDatas.map((item, index: number) => {
                  return (
                    <RedeemUnderlyItem
                      key={index}
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid item xs={7}>
                        <span className="amount">
                          ≈{new BigNumber(item.amount).times(new BigNumber(percent)).toFixed(6)}
                        </span>
                        <span className="price">
                          {priceData &&
                            currencyFormat(
                              new BigNumber(item.amount)
                                .times(new BigNumber(percent))
                                .times(new BigNumber(priceData && priceData[item.id].usd))
                                .toFixed(2)
                            )}
                        </span>
                      </Grid>
                      <Grid item xs={4} style={{ textAlign: 'right' }}>
                        <Unit>{item.name}</Unit>
                      </Grid>
                    </RedeemUnderlyItem>
                  )
                })}

              <Divider />
              <RedeemUnderlyTotal container direction="row" justifyContent="space-between" alignItems="flex-start">
                <Grid item xs={4}>
                  <span className="title">Total</span>
                </Grid>
                <Grid item xs={7} style={{ textAlign: 'right' }}>
                  <span className="num">{currencyFormat(totalUsd != 'NaN' ? totalUsd : 0)}</span>
                </Grid>
              </RedeemUnderlyTotal>
            </>
          ) : (
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={7} style={{ fontSize: '24px', color: '#222', fontWeight: 600 }}>
                ≈ {sellAmout}
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <Unit>USDT</Unit>
              </Grid>
            </Grid>
          )}
        </div>
      </RedeemTabBox>

      <TransBtn typeNum={btnState} isLoading={isLoading} clickFunc={handleClick} lup={fundData.lup} />
      <TransDialog modalStatus={modalStatus} confirmStatus={confirmStatus} handleClose={handleClose} />
    </>
  )
}

interface TransIptProps {
  val: null | string
  currency?: string
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
}
function TransInputLine({ val, handleChange, currency }: TransIptProps) {
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item xs={8}>
        <TextField
          hiddenLabel
          id="filled-hidden-label-small"
          variant="filled"
          size="small"
          value={val}
          type="number"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={4} style={{ textAlign: 'right' }}>
        <Unit>{currency ?? 'USDT'}</Unit>
      </Grid>
    </Grid>
  )
}
const TransButton = styled(Button)`
  background-color: #2b878c;
  color: #fff;
  width: 100%;
  margin-top: 20px;
  &:hover {
    background-color: #2b878c;
  }
`
const DisabledButton = styled(TransButton)`
  background-color: #cccccc;
  margin-top: 6px;
  &:hover {
    background-color: #cccccc;
  }
`
const LupTip = styled.span`
  font-size: 14px;
  margin-top: 20px;
  display: inline-block;
`
interface BtnProps {
  typeNum: number
  isLoading: boolean
  clickFunc: () => void
  lup: string
}
function TransBtn({ typeNum, clickFunc, isLoading, lup }: BtnProps) {
  const types = ['解锁', '授权 & 购买', '购买', '赎回']
  const nowTimestep = Date.parse(new Date().toString()) / 1000
  const checkLup = () => {
    return new BigNumber(lup).gt(nowTimestep)
  }
  return (
    <>
      {checkLup() && typeNum !== 0 ? (
        <>
          <LupTip>
            该产品处于封闭期，封闭期内不允许购买/赎回操作，封闭期将于{' '}
            {getFormattedTimeString(Number(lup), 'yyyy-MM-DD HH:mm')} 结束
          </LupTip>
          <DisabledButton variant="contained">{types[typeNum]}</DisabledButton>
        </>
      ) : (
        <TransButton variant="contained" onClick={clickFunc}>
          {types[typeNum]}
          {'  '}
          {isLoading ? <Loader stroke="white" /> : null}
        </TransButton>
      )}
    </>
  )
}
interface TransDialogProps {
  modalStatus: boolean
  confirmStatus: boolean
  handleClose: () => void
}
const DialogIconBox = styled.div`
  padding: 60px 0;
  display: flex;
  width: 100%;
  justify-content: center;
`
function TransDialog({ modalStatus, confirmStatus, handleClose }: TransDialogProps) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(modalStatus || confirmStatus)
  useEffect(() => {
    setDialogOpen(modalStatus || confirmStatus)
  }, [modalStatus, confirmStatus])
  return (
    <TransactionConfirmationModal confirmStatus={dialogOpen} handleConfirmClose={handleClose}>
      {modalStatus ? (
        <>
          <DialogIconBox>
            <CircularProgress size={90} />
          </DialogIconBox>
          <AwaitP>等待确认</AwaitP>
          <TopicP>在您的钱包中确认这笔交易</TopicP>
        </>
      ) : null}
      {confirmStatus ? (
        <>
          <DialogIconBox>
            <ArrowCircleUpIcon src={arrowUpCircle} />
          </DialogIconBox>
          <AwaitP>已提交交易</AwaitP>
        </>
      ) : null}
    </TransactionConfirmationModal>
  )
}
function TransAlert({ state, showAlert }: { state: string; showAlert: boolean }) {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(true)
  }, [showAlert])

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }
  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={state === 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
          {state === 'success' ? '交易成功' : '交易失败'}
        </Alert>
      </Snackbar>
    </>
  )
}
