import { useWeb3React } from '@web3-react/core'
import { network } from '../../connectors'
import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useViewport } from '../../components/ViewportProvider'
import Wallet from '../../components/Wallet'
import DehazeIcon from '@material-ui/icons/Dehaze'
import { default_chainId } from '../../connectors/index'
import Logo from '../../assets/images/logo.png'
import { Alert } from '@material-ui/core'

const Content = styled.div`
  width: 1160px;
  padding: 0 10px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  @media screen and (max-width: 1160px) {
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
    height: 100%;
    display: inline-block;
  }
`
const Menus = styled.nav`
  height: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1160px) {
    height: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 12px 12px -8px rgba(183, 186, 186, 0.16);
    position: relative;
    z-index: 99;
  }
`
const AddressLink = styled.a`
  margin: 0 15px;
  color: #666666;
  font-size: 16px;
  line-height: 69px;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  &:hover {
    color: #222222;
  }

  &.active {
    color: #222222;
  }
  @media screen and (max-width: 1160px) {
    margin: 0 15px;
    color: #666666;
    font-size: 16px;
    line-height: 42px;
    font-weight: 600;
    text-decoration: none;
    position: relative;
    width: 100%;

    &:hover {
      color: #222222;
    }

    &.active {
      color: #222222;
    }
  }
`
const LogoImg = styled.img`
  height: 24px;
  margin-top: 24px;
`

export default function Header() {
  const { chainId, library, activate } = useWeb3React()
  const [contentIsShow, setContentIsShow] = useState<boolean>(true)
  const [menuBtnIsShow, setMenuBtnIsShow] = useState<boolean>(true)
  const [isShowTips, setIsShowTips] = useState<boolean>(false)
  const { width } = useViewport()
  const breakpoint = 1160
  useEffect(() => {
    if (!library || chainId !== default_chainId) {
      activate(network)
    }
  }, [chainId])

  useEffect(() => {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('imToken') != -1) {
      setIsShowTips(true)
    }
  }, [])

  useEffect(() => {
    if (width > breakpoint) {
      setMenuBtnIsShow(false)
      setContentIsShow(true)
    } else {
      setMenuBtnIsShow(true)
      setContentIsShow(false)
    }
  }, [width])
  const closeAlert = () => {
    setIsShowTips(false)
  }
  return (
    <header
      style={
        width > breakpoint
          ? {
              height: '68px',
              width: '100%',
              backgroundColor: '#ffffff',
              position: 'fixed',
              top: 0,
              zIndex: 99,
            }
          : { height: '52px', zIndex: 999, boxShadow: ' 0px 12px 12px -8px rgba(183, 186, 186, 0.16)' }
      }
    >
      <Content>
        <div>
          <AddressLink href="#/">
            <LogoImg src={Logo} alt="logo" />
          </AddressLink>
        </div>
        {isShowTips ? (
          <Alert onClose={closeAlert} severity="warning" sx={{ padding: '5px' }}>
            您正在使用imToken钱包访问，由于政策原因，使用imToken默认节点可能无法完成购买/赎回操作，请使用其他钱包或更改imToken默认节点
          </Alert>
        ) : (
          ''
        )}
        {menuBtnIsShow ? (
          <DehazeIcon
            style={{
              float: 'right',
              display: 'inline-block',
              marginTop: '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setMenuBtnIsShow(true)
              setContentIsShow(!contentIsShow)
            }}
          ></DehazeIcon>
        ) : (
          <></>
        )}
        {contentIsShow ? (
          <Menus>
            <AddressLink href="#/">首页</AddressLink>
            {/*<AddressLink href="#/fund">fund</AddressLink>*/}
            {/* <ChangeLanguage /> */}
            <Wallet />
          </Menus>
        ) : (
          <></>
        )}
      </Content>
    </header>
  )
}
