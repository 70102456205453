import { default_chainId } from '../connectors'
import { SupportedChainId } from './chains'
import KFUNLVIcon from '../assets/images/fund/fund_UO1.png'

export interface FUNDS_ITEM {
  fundAddress: string
  strategyAddress: string
  autoLiquidityAddress?: string
  decimals: number
  types: string[]
  minPurchase: number
  icon: string
  edition: string
}

const addressData = {
  [SupportedChainId.MAINNET]: [
    {
      fundAddress: '0xD05859e5A8CB0e4Ca19B62B9199b8f4216603cB9',
      strategyAddress: '0x315CEd1295cE5efCd237FAC6C0BBB84d50d69510',
      decimals: 6,
      types: ['LIQUIDITY', 'VAULT'],
      minPurchase: 100000,
      icon: KFUNLVIcon,
      edition: 'v1',
    },
  ],
  [SupportedChainId.LOCAL]: [
    {
      fundAddress: '0xD05859e5A8CB0e4Ca19B62B9199b8f4216603cB9',
      strategyAddress: '0x315CEd1295cE5efCd237FAC6C0BBB84d50d69510',
      decimals: 6,
      types: ['LIQUIDITY', 'VAULT'],
      minPurchase: 100000,
      icon: KFUNLVIcon,
      edition: 'v1',
    },
  ],
  [SupportedChainId.KOVAN]: [
    {
      fundAddress: '0x00510697F5188D71cc824a0D43CBC0e8d156810c',
      strategyAddress: '0xd510C8f8985cE9fd4618b7826D67EfE838e3e6E8',
      // autoLiquidityAddress: '0x2b357cd9fdE3D6297C9D986A98A6814317b6A8a0',
      decimals: 6,
      types: ['LIQUIDITY', 'VAULT'],
      minPurchase: 100,
      icon: KFUNLVIcon,
      edition: 'v1',
    },
    {
      fundAddress: '0x44438c22681DFACE24F6F8eDf4C2c6bE75B3A7de',
      strategyAddress: '0xC72552788Ccf6168d50972924966B1344ce84876',
      autoLiquidityAddress: '0x2b357cd9fdE3D6297C9D986A98A6814317b6A8a0',
      decimals: 6,
      types: ['LIQUIDITY', 'VAULT'],
      minPurchase: 100,
      icon: KFUNLVIcon,
      edition: 'v2',
    },
  ],
}

export const FUNDS: FUNDS_ITEM[] = addressData[default_chainId]
