import React, { useEffect, useMemo } from 'react'
import { NonfungiblePositionManager, Position } from '@uniswap/v3-sdk'
import { usePool } from 'hooks/usePool'
import { useToken } from 'hooks/useToken'
import { DefaultTheme } from 'styled-components/macro'
import { PositionDetails } from 'types/position'
import { Price, Token } from '@uniswap/sdk-core'
import { unwrappedToken } from 'utils/unwrappedToken'
import { DAI, USDC, USDT, WBTC, WETH9_EXTENDED } from '../../constants/tokens'
import { ethers } from 'ethers'
import { useUpdateFunds } from '../../state/funds/hook'
import { useAppSelector } from 'state/hooks'

interface CustomPositionListItemProps {
  theme: DefaultTheme
  chainId: number | undefined
  account: string | null | undefined
  library: ethers.providers.Web3Provider | undefined
  positionManager: NonfungiblePositionManager | null
}

export interface PositionListItemProps {
  positionDetails: PositionDetails
  customPositionDetails?: CustomPositionListItemProps
  address: string
}

interface uniquePosition {
  amount0?: string
  amount1?: string
  token0?: string
  token1?: string
  tokenId: string
  ContractAddress: string
}
export function getPriceOrderingFromPositionForUI(position?: Position): {
  priceLower?: Price<Token, Token>
  priceUpper?: Price<Token, Token>
  quote?: Token
  base?: Token
} {
  if (!position) {
    return {}
  }

  const token0 = position.amount0.currency
  const token1 = position.amount1.currency
  // if token0 is a dollar-stable asset, set it as the quote token
  const stables = [DAI, USDC, USDT]
  if (stables.some((stable) => stable.equals(token0))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }
  // if token1 is an ETH-/BTC-stable asset, set it as the base token
  const bases = [...Object.values(WETH9_EXTENDED), WBTC]
  if (bases.some((base) => base.equals(token1))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if both prices are below 1, invert
  if (position.token0PriceUpper.lessThan(1)) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // otherwise, just return the default
  return {
    priceLower: position.token0PriceLower,
    priceUpper: position.token0PriceUpper,
    quote: token1,
    base: token0,
  }
}

export default function PositionListItem({ positionDetails, customPositionDetails, address }: PositionListItemProps) {
  const {
    token0: token0Address,
    token1: token1Address,
    fee: feeAmount,
    liquidity,
    tickLower,
    tickUpper,
    tokenId,
  } = positionDetails
  const token0 = useToken(token0Address)
  const token1 = useToken(token1Address)
  const { onUpdatePositionList } = useUpdateFunds()

  const { currency0, currency1 } = useMemo(() => {
    console.log(token0 ? unwrappedToken(token0) : undefined, token1 ? unwrappedToken(token1) : undefined)
    const currency0 = token0 ? unwrappedToken(token0) : undefined
    const currency1 = token1 ? unwrappedToken(token1) : undefined
    return { currency0, currency1 }
  }, [token0, token1])

  // construct Position from details returned
  const [, pool] = usePool(currency0 ?? undefined, currency1 ?? undefined, feeAmount)
  const state = useAppSelector((state) => state.funds)
  // 获取position
  const position: Position | undefined = useMemo(() => {
    if (pool) {
      return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
    }
    return undefined
  }, [liquidity, pool, tickLower, tickUpper])

  // 数组对象去重
  // TODO 上面这一段去重写复杂了,并且又是用了==而不是===，看下面这一段逻辑是不是更清晰一些(盲写的，没测试过)？
  // 已修改 已测试
  function unique(arr: uniquePosition[]): uniquePosition[] {
    return arr.reduce((t, v) => {
      if (!t.find((x) => x.tokenId === v.tokenId)) {
        t.push(v)
      }
      return t
    }, [] as uniquePosition[])
  }

  useEffect(() => {
    if (position && tokenId) {
      onUpdatePositionList(
        unique([
          {
            ContractAddress: address,
            tokenId: tokenId.toString(),
            token0: JSON.stringify(position.pool.token0),
            token1: JSON.stringify(position.pool.token1),
            amount0: position.mintAmounts.amount0.toString(),
            amount1: position.mintAmounts.amount1.toString(),
          },
          ...state.positionList,
        ])
      )
    }
  }, [position, tokenId])

  return <></>
}
