import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './pages/App'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import store from './state'
import { createWeb3ReactRoot, useWeb3React, Web3ReactProvider } from '@web3-react/core'
import getLibrary from './utils/getLibrary'
import { NetworkContextName } from './constants/misc'
import Web3ReactManager from './components/Web3ReactManager'
import { LanguageProvider } from './i18n'
import { StyledEngineProvider } from '@material-ui/core/styles'
import { ViewportProvider } from 'components/ViewportProvider'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import ListsUpdater from './state/lists/updater'
import UserUpdater from './state/user/updater'
import ApplicationUpdater from './state/application/updater'
import LogsUpdater from './state/logs/updater'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}
function Updaters() {
  return (
    <>
      {/* <RadialGradientByChainUpdater /> */}
      <ListsUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
      <LogsUpdater />
    </>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <LanguageProvider>
          <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ProviderNetwork getLibrary={getLibrary}>
              <StyledEngineProvider injectFirst>
                <Updaters />
                <ViewportProvider>
                  <Web3ReactManager>
                    <App />
                  </Web3ReactManager>
                </ViewportProvider>
              </StyledEngineProvider>
            </Web3ProviderNetwork>
          </Web3ReactProvider>
        </LanguageProvider>
      </HashRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
