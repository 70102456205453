import { FUNDS } from '../../constants/funds'
import { useFundData, useFundsState, useUpdateFunds } from '../../state/funds/hook'
import {
  useUo1FundContract,
  useUo2FundContract,
  useUo1StrategyContract,
  useUo2StrategyContract,
  useWETHContract,
} from '../../hooks/useContract'
import { useWeb3React } from '@web3-react/core'
import { useEffect, useMemo, useRef, useState } from 'react'
import BigNumber from 'bignumber.js'
import { BigNumber as BN } from '@ethersproject/bignumber'
import { TOKENS } from '../../constants/tokens'
import { Contract } from '@ethersproject/contracts'
import { getProviderOrSigner } from '../../utils'
import ERC20_ABI from 'abis/erc20.json'

import PositionList from './PositionList'

import { useAppSelector } from 'state/hooks'
import { default_chainId } from '../../connectors'
import { useUpdateUnderlyData } from '../../state/underly/hook'
import { getCoingeckoSimplePrice, getSimplePrice } from '../../apis/CoinGecko'
import { Uo1FundAbi, Uo1StrategyAbi, Uo2FundAbi, Uo2StrategyAbi } from 'abis/types'
import { useMultipleContractSingleData, useSingleCallResult } from 'state/multicall/hooks'
import { uniqueArr } from 'utils/uniqueArr'
import { addressesToIds, getERC20Balances, getTokenItemByAddress, updateTokensByAddresses } from 'utils/tokens'
import { ISimplePrice1 } from './DetailBase'
import { useUpdateTokenData } from 'state/tokens/hooks'
import { useStrategyUnderlyings, useStrategyWorksPos } from 'hooks/useKYContract'
import { Interface } from '@ethersproject/abi'
import { useUpdateStrategyData } from 'state/strategy/hooks'
// import { useV3PositionsFromTokenIds } from '../../hooks/useV3Positions'
import { PositionInfo } from './PositionDetails'

interface Itokens {
  ContractAddress: string
  address: string
  change24h: number
  decimals: number
  icon: string
  id: string
  name: string
  priceUsd: number
  trend: string
  underlyBalance: string
}

interface ITokenAddress {
  addrs: string
  tokenAdd: string[]
}
interface StrategyAddressArr {
  address: string
  edition: string
}
export default function UpdateFunds() {
  const addresses = FUNDS.map((item) => {
    return {
      address: item.fundAddress,
      edition: item.edition,
      strategyAddress: item.strategyAddress,
    }
  })
  // TODO:现在暂时写死 没想到更好的解决办法
  const uo1FundContract = useUo1FundContract(addresses[0].address)
  // const uo2FundContract = useUo2FundContract(addresses[1].address)

  const strategyAddress = FUNDS.reduce((t, v) => {
    t.push({
      address: v.strategyAddress,
      edition: v.edition,
    })
    if (v.autoLiquidityAddress) {
      t.push({
        address: v.autoLiquidityAddress,
        edition: v.edition,
      })
    }
    return t
  }, [] as StrategyAddressArr[])
  return (
    <>
      {addresses.map((item) => {
        return (
          <UpdateFund
            key={item.address}
            address={item.address}
            edition={item.edition}
            fundContract={item.edition === 'v1' ? uo1FundContract : uo1FundContract}
          />
        )
      })}
      {strategyAddress.map((item) => {
        return (
          <UpdateStrategy
            key={item.address}
            strategyAddress={item.address}
            useStrategyContract={item.edition === 'v1' ? useUo1StrategyContract : useUo2StrategyContract}
          />
        )
      })}
    </>
  )
}
function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>()

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  function tick() {
    if (savedCallback.current) savedCallback.current()
  }
  // Set up the interval.
  useEffect(() => {
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
    return
  }, [delay])
}
// TODO: fundContract 类型暂定any 因为需要同时兼容uo1和uo2不同的abi类型
function UpdateFund({ address, edition, fundContract }: { address: string; edition: string; fundContract: any }) {
  const [count, setCount] = useState(0)
  useInterval(() => {
    setCount(count + 1)
  }, 15000)
  const addressArr: string[] = []
  const { chainId, account, library } = useWeb3React()
  const { onUpdateFunds, onUpdateUnderly } = useUpdateFunds()
  const { onUpdateUnderlyData } = useUpdateUnderlyData()
  // const fundData = FUNDS.find((item) => item.fundAddress === address)
  const fundData = useFundData(address)

  const funds = useFundsState().funds

  const wethContract = useWETHContract()
  const strategyAddress = FUNDS.filter((item) => item.fundAddress.toLowerCase() === address.toLowerCase())[0]
    .strategyAddress
  if (strategyAddress && !addressArr.includes(strategyAddress)) addressArr.push(strategyAddress)
  const uo1StrategyContract = useUo1StrategyContract(strategyAddress)
  const uo2StrategyContract = useUo2StrategyContract(strategyAddress)
  const strategyContract = edition === 'v1' ? uo1StrategyContract : uo2StrategyContract
  const autoLiquidityAddress = FUNDS.filter((item) => item.fundAddress.toLowerCase() === address.toLowerCase())[0]
    .autoLiquidityAddress
  if (autoLiquidityAddress && !addressArr.includes(autoLiquidityAddress)) addressArr.push(autoLiquidityAddress)
  const autoLiquidityContract = edition === 'v1' ? uo1StrategyContract : uo2StrategyContract

  const { onUpdateTokenPrice } = useUpdateTokenData()
  useEffect(() => {
    if (chainId !== default_chainId) return
    const newFundData = { ...fundData }
    newFundData.address = address
    Promise.all([
      getFundSymbol(),
      getFundName(),
      getFundAssets(),
      getFundTotalSupply(),
      getFundLup(),
      getFundUnderlyings(strategyContract),
      getFundUnderlyings(autoLiquidityContract),
    ]).then(async (res) => {
      const [symbol, name, assets, totalSupply, lup, underlyings, underlyingsAuto] = res
      newFundData.symbol = symbol
      newFundData.fullName = name
      newFundData.assets = (assets ?? 0).toString()
      newFundData.totalSupply = (totalSupply ?? 0).toString()
      newFundData.lup = (lup ?? 0).toString()
      newFundData.tokens = underlyingsAuto ? [...underlyings, ...underlyingsAuto] : underlyings
      const fees = await Promise.all([getFundFees(0), getFundFees(1), getFundFees(2), getFundFees(3), getFundFees(4)])
      newFundData.fees = fees.map((item) => {
        return item
          ? new BigNumber(item[0].toString())
              .dividedBy(new BigNumber(item[1].toString()).comparedTo(0) === 0 ? 1 : item[1].toString())
              .times(100)
              .toNumber()
          : 0
      })
      // await handleStrategyData()
      const fundBalance = await getFundBalance()
      newFundData.fundBalance = (fundBalance ?? 0).toString()
      // const newFundIndex = [...funds].findIndex((element) => element.address === address)
      // const newFunds = [...funds]
      // newFunds[newFundIndex] = newFundData
      // console.log(newFundData, newFunds)
      // console.log(newFundData, '_________________________________')

      // const newFunds = Object.assign([], funds, [newFundData])
      // if (newFundData.symbol && newFundData.address) {
      onUpdateFunds([newFundData])
      // }
    })
  }, [chainId, account, count])
  const getFundSymbol = async () => {
    return await fundContract?.symbol()
  }
  const getFundName = async () => {
    return await fundContract?.name()
  }
  const getFundFees = async (index: number) => {
    return await fundContract?.getFee(index)
  }
  const getFundAssets = async () => {
    return await fundContract?.assets()
  }
  const getFundTotalSupply = async () => {
    return await fundContract?.totalSupply()
  }
  const getFundBalance = async () => {
    if (!account) return undefined
    return await fundContract?.balanceOf(account)
  }

  const getFundUnderlyings = async (contract: Uo1StrategyAbi | Uo2StrategyAbi | null) => {
    //   // const res = useSingleCallResult(contract, 'getUnderlyings')
    //   // const underlyings = res?.result?.[0]?.toString()
    //   // if (typeof underlyings === 'string') {
    //   //   return underlyings.split(',')
    //   // }
    return []
    //   // if (contract) {
    //   //   return await contract.getUnderlyings()
    //   // }
  }

  const getFundLup = async () => {
    return await fundContract?.lup()
  }
  // const tokensArr = getTokensRate()
  const state = useAppSelector((state) => state.funds)

  // TODO 这里是钓鱼的，故意没写todo让大家自己发现，结果还是没有人找到
  const underlyArr = state.underly.reduce(
    (r: any, x: any) => ((r[x.ContractAddress] || (r[x.ContractAddress] = [])).push(x), r),
    {}
  )
  const positionArr = state.positionList.reduce(
    (r: any, x: any) => ((r[x.ContractAddress] || (r[x.ContractAddress] = [])).push(x), r),
    {}
  )
  useEffect(() => {
    const dataJson = { positionList: positionArr, underlyList: underlyArr }
    onUpdateUnderlyData(dataJson)
  })

  // Promise.all()
  return addressArr.length > 0 ? (
    <>
      {addressArr.map((item) => (
        <PositionList key={item} address={item} />
      ))}
    </>
  ) : null
}

function UpdateStrategy({
  strategyAddress,
  useStrategyContract,
}: {
  strategyAddress: string
  useStrategyContract: any
}) {
  const [count, setCount] = useState(0)
  useInterval(() => {
    setCount(count + 1)
  }, 15000)
  console.log(count, 'count')
  const addressArr: string[] = []
  const strategyContract = useStrategyContract(strategyAddress)
  const { chainId, account, library } = useWeb3React()
  const [underlyings, setUnderlyings] = useState<string[]>()
  const { onUpdateUnderlyData } = useUpdateUnderlyData()
  const wethContract = useWETHContract()
  const { onUpdateTokenPrice } = useUpdateTokenData()
  const { onUpdateStrategyUnderly } = useUpdateStrategyData()

  const underlying = useStrategyUnderlyings(strategyContract)
  const workPos = useStrategyWorksPos(strategyContract)

  useEffect(() => {
    if (!underlying) return
    setUnderlyings(underlying.split(','))
  }, [underlying])

  // 获取策略合约的底层资产，并更新他们的价格到redux
  // 获取策略合约的底层资产，并更新他们的数量到redux
  if (underlyings) {
    updateTokensByAddresses(underlyings, onUpdateTokenPrice)
    console.log(underlyings, 'balancesPromise')
    const balancesPromise = getERC20Balances(strategyAddress, underlyings, library, TOKENS)

    Promise.all(balancesPromise).then((balances) => {
      console.log(balances, strategyAddress)

      balances.map((x) => {
        onUpdateStrategyUnderly(strategyAddress, x.address, x)
      })
    })
  }

  // 获取策略的仓位，并把仓位当时的token0和token1数量更新到redux
  const workPosBN = useMemo(() => {
    if (!workPos) return undefined
    return workPos.split(',')
  }, [workPos])
  // const { loading, positions: workPostitions } = useV3PositionsFromTokenIds(workPosBN)
  // useEffect(() => {
  //   if (loading || !workPos) return
  //   if (!workPostitions || workPostitions.length === 0) return
  //   setPositions([...workPostitions])
  // }, [loading, workPos])

  return (
    <>
      {workPosBN?.map((item) => {
        return <PositionInfo key={item} strategyAddress={strategyAddress} tokenId={item} />
      })}
    </>
  )
}
